import React from 'react'
import { LevelRange, Unit } from './classes'


export const GameContext = React.createContext<{
    level: LevelRange,
    setLevel: React.Dispatch<React.SetStateAction<LevelRange>>,
    cumulativeLevel: number,
    setCumulativeLevel: React.Dispatch<React.SetStateAction<number>>,
    gold: number,
    setGold: React.Dispatch<React.SetStateAction<number>>,
    champPool: Unit[],
    setChampPool: React.Dispatch<React.SetStateAction<Unit[]>>,
    champShop: (Unit|undefined)[],
    setChampShop: React.Dispatch<React.SetStateAction<(Unit|undefined)[]>>,
    champBench: (Unit|undefined)[],
    setChampBench: React.Dispatch<React.SetStateAction<(Unit|undefined)[]>>,
    gameActive: boolean,
    time: number,
    setTime: React.Dispatch<React.SetStateAction<number>>,
    sellActive: boolean,
    setSellActive: React.Dispatch<React.SetStateAction<boolean>>,
    sellAreaHovered: boolean,
    setSellAreaHovered: React.Dispatch<React.SetStateAction<boolean>>,
    activeTraits: any,
    setActiveTraits: React.Dispatch<React.SetStateAction<any>>,
    gameHistory: any[],
    setGameHistory: React.Dispatch<React.SetStateAction<any>>,
    xpKeybind: string,
    setXpKeybind: React.Dispatch<React.SetStateAction<string>>
    shopKeybind: string,
    setShopKeybind: React.Dispatch<React.SetStateAction<string>>
    sellKeybind: string,
    setSellKeybind: React.Dispatch<React.SetStateAction<string>>
  }>({
    level: 7,
    setLevel: () => {},
    cumulativeLevel: 74,
    setCumulativeLevel: () => {},
    gold: 50,
    setGold: () => {},
    champPool: [],
    setChampPool: () => {},
    champShop: [],
    setChampShop: () => {},
    champBench: [],
    setChampBench: () => {},
    gameActive: false,
    time: 50,
    setTime: () => {},
    sellActive: false,
    setSellActive: () => {},
    sellAreaHovered: false,
    setSellAreaHovered: () => {},
    activeTraits: {},
    setActiveTraits: () => {},
    gameHistory: [],
    setGameHistory: () => {},
    xpKeybind: 'f',
    setXpKeybind: () => {},
    shopKeybind: 'd',
    setShopKeybind: () => {},
    sellKeybind: 'e',
    setSellKeybind: () => {}
  })